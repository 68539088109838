import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import { required } from 'vuelidate/lib/validators';
import DatePicker from 'vue2-datepicker';
export default {
  name: 'addFmsGenerateBatch',
  components: {
    DatePicker
  },
  watch: {
    currentPage: function() {
      this.getTempBatchBill();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getTempBatchBill();
    }
  },
  data() {
    return {
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      selectAllCheckBox: false,
      unsubscribe: null,
      editMode: true,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      loader: false,
      showValueSetModal: false,
      parent_value_set_id: null,
      vsetCode: null,
      setTimeVsetCode: null,
      selectedLegalEntity: {
        value: null,
        text: null
      },
      selectedBillingCycle: {
        value: null,
        text: null
      },
      selectedOU: {
        value: null,
        text: null
      },
      selectedFmsProj: {
        value: null,
        text: null
      },
      tempBatchBillList: [],
      tempBatchBillFiedls: [
        {
          key: 'plan_name'
        },
        {
          key: 'period_from',
        },
        {
          key: 'period_to',
        },
        {
          key: 'plan_unit_type_vset_meaning',
          label: 'Project Unit Type'
        },
        {
          key: 'project_name',
          label: 'Project'
        },
        {
          key: 'bill_date',
        },
        {
          key: 'due_date',
        },
        {
          key: 'gl_date',
          label: 'GL Date'
        },
        {
          key: 'value_date',
        }
      ]
    };
  },
  validations: {
    selectedLegalEntity: {
      text: { required }
    },
    selectedBillingCycle: {
      text: { required }
    }
  },
  mounted() {
    if (this.batchBillId) {
      this.getBatchBillData();
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          this.addEditGenBatch();
        }
      }
    });
  },
  methods: {
    addEditGenBatch() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          bill_cycle_hdr_id: this.selectedBillingCycle.value,
        };
        this.loader = true;
        this.$store
          .dispatch('fmsTransaction/addEditFmsGenBatch', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.editMode = false;
              this.isSuccess = true;
              this.respMessage = response.data.message;
            } else {
              this.isSuccess = false;
              this.respMessage = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    generateTempBatchBill() {
      // if (!this.$v.$invalid) {
        const payload = {
          bill_cycle_hdr_id: this.selectedBillingCycle.value,
        };
        this.loader = true;
        this.$store
          .dispatch('fmsTransaction/generateTempBatchBill', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.getTempBatchBill();
              this.editMode = false;
              this.isSuccess = true;
              this.respMessage = response.data.message;
            } else {
              this.isSuccess = false;
              this.respMessage = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      // } else {
      //   alert('Please Fill Mandatory Fields to Generate Temp Batch.')
      // }
    },
    getTempBatchBill() {
      this.pyaload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id:this.selectedLegalEntity.value,
        bill_cycle_hdr_id:this.selectedBillingCycle.value
      };
      this.loader = true;
      this.$store
        .dispatch('fmsTransaction/getTempBatchBill', this.pyaload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.tempBatchBillList = response.data.data.page.map(data => {
              data.selectbox = false;
              data.df = true;
              data.bill_date = data.bill_date ? new Date(data.bill_date) : null;
              data.due_date = data.due_date ? new Date(data.due_date) : null;
              data.gl_date = data.gl_date ? new Date(data.gl_date) : null;
              data.value_date = data.value_date
                ? new Date(data.value_date)
                : null;
              return data;
            });
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    checkValueSetDependency(vsetCode) {
      if (
        vsetCode == appStrings.VALUESETTYPE.BILLING_CYCLE &&
        !this.selectedLegalEntity.value
      ) {
        alert('Please select Legal Entity');
      } else {
        this.openValueSetModal(vsetCode);
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (
        this.vsetCode === appStrings.VALUESETTYPE.OU_LIST ||
        this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT ||
        this.vsetCode === appStrings.VALUESETTYPE.BILLING_CYCLE
      ) {
        this.parent_value_set_id = this.selectedLegalEntity.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.selectedLegalEntity.text = item.org_name;
        this.selectedLegalEntity.value = item.org_id;
        this.selectedBillingCycle = { value: null, text: null };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BILLING_CYCLE) {
        this.selectedBillingCycle.text = item.billing_cycle_name;
        this.selectedBillingCycle.value = item.billing_cycle_hdr_id;
      }
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.selectedLegalEntity = { value: null, text: null };
        this.selectedBillingCycle = { value: null, text: null };
      } else if (vsetCode === appStrings.VALUESETTYPE.BILLING_CYCLE) {
        this.selectedBillingCycle = { value: null, text: null };
      } 
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
